import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO, Layout, PreviewCompatibleImage } from "../components";
import Content, { HTMLContent } from "../components/Content";
import { Container, Wrapper, Row } from "../ui/LayoutComponents";
import { TitleH1, IntroText } from "../ui/Texts";
import { ImageContainer } from "../ui";

export const InterviewPostTemplate = ({
  title,
  intro,
  authors,
  slug,
  excerpt,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;
  const description = intro ? intro : authors[0].description;

  return (
    <>
      <SEO article title={title} description={description} url={slug} />
      <Wrapper>
        <Container>
          <TitleH1 size="36px" sizeLg="32px" sizeMd="26px" sizeSm="20px">
            {title}
          </TitleH1>
          <div style={{ margin: "3rem 0" }}>
            {intro && <IntroText>{intro}</IntroText>}
            {authors &&
              authors.map((author) => (
                <Row key={author.description} mobile margin="2rem 0" alignItems="center">
                  <ImageContainer maxWidth="150px" imgLeft margin="2rem">
                    <PreviewCompatibleImage imageInfo={author.image} />
                  </ImageContainer>
                  <IntroText>{author.description}</IntroText>
                </Row>
              ))}
          </div>
          <PostContent className="interview" content={content} />
        </Container>
      </Wrapper>
    </>
  );
};

InterviewPostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
  authors: PropTypes.array,
  slug: PropTypes.string,
  excerpt: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
};

const InterviewPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <InterviewPostTemplate
        title={post.frontmatter.title}
        intro={post.frontmatter.intro}
        authors={post.frontmatter.authors}
        slug={post.fields.slug}
        excerpt={post.excerpt}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

InterviewPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default InterviewPost;

export const pageQuery = graphql`
  query InterviewPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        intro
        authors {
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
      }
    }
  }
`;
